.product-list-container {
    display: flex;
    flex-direction: column;
    max-width: 1875px;
    margin: 0 auto;
    margin-top: 5px;
    padding: 20px;
    margin-bottom: 10px;
}

.product-list-container h2 {
    font-weight: 500;
    font-family: Arial, Helvetica, sans-serif;
    margin: auto;
    margin-top: 15px;
    margin-bottom: 30px;
    text-transform: uppercase;
}

.product-list-container .filter-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0px;
    height: 48px;
    line-height: 48px;
    vertical-align: middle;
    width: 100%;
}

.product-list-container .filter-section .left {
    display: flex;
    flex-direction: row;
    height: 40px;
    line-height: 40px;
    vertical-align: middle;
    width: fit-content;
}

.product-list-container .filter-section .right {
    position: relative;
    left: 0%;
}

.product-list-container .filter-section select {
    padding: 8px;
    font-size: 14px;
}

.product-list-container .categoryFilterTitle {
    text-align: center;
    font-weight: 500;
    font-size: 30px;
}

.product-list-container .product-list {
    list-style: none;
    padding: 0;
}

.product-list-container .product-list li {
    margin-bottom: 10px;
}

.produft-grid-wrapper {
    min-height: 20vh;
}

.produft-grid-wrapper h2 {
    text-transform: none;
    margin-top: 50px;
    font-size: 28px;
    text-align: center;
    font-weight: 500;
}

.product-grid {
    display: grid;
    margin-top: 25px;
    grid-template-columns: repeat(auto-fill, minmax(182.5px, 1fr));
    gap: 5.5px;
}

.input-textsearch {
    display: block;
    width: 250px;
    font-size: 16px;
    height: 41px;
    padding: 1px 10px;
    margin-top: -2px;
    margin-left: 5px;
    animation: all 5s linear infinite;
    border: 1px solid black;
  }

@media screen and (min-width: 300px) and (max-width:767px) and (orientation: portrait) {
    .product-list-container{
      max-width:100%;
      display: flex;
      justify-content: center;
      min-height: 27.5vh;
    }
    .produft-grid-wrapper h2{
      font-size: 16px;
    }
  
    .produft-grid-wrapper{
      display: flex;
      justify-content: center;
    }
    .product-grid{
      width: 95vw;
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
      gap: 3px;
      margin-top: -15px;  
  
    }
    .product-list-container .filter-section .left{
      display: flex;
      flex-direction: row;
      height: 40px;
      justify-content: space-between;
      width: 100vw;
      margin-top: -40px;
    }
    .product-list-container .input-textsearch{
      width: 35vw;
      height: 34px;
      margin-top: 0.25px;
      padding: 0px 5px;
      border: 1px solid black;
      font-size: 14px;
    }
  }
  
  
  @media screen and (min-width: 768px) and (orientation: portrait) {
    .product-list-container{
      max-width:90%;
    
    }
    .produft-grid-wrapper h2{
      font-size: 16px;
    }
    .product-grid{
      width: 90vw;
      margin-left: -15px;  
      margin: auto;
      grid-template-columns: repeat(auto-fill, minmax(110.5px, 1fr));
      gap: 6px;
    }
    .product-list-container .input-textsearch{
      width: 170px;
    }

    .input-textsearch {
      margin-top: 0.5px;
      height: 32px;
    }

    
  
  }
  @media screen and (min-width: 1590px) and (max-width:1910px) and (orientation: landscape) {
    .product-list-container {

      max-width: 1340px;

    }
  }
  
  @media screen and (min-width: 1350px) and (max-width:1590px) and (orientation: landscape) {
    .product-list-container {
      display: flex;
      flex-direction: column;
      max-width: 1100px;
      padding: 0px;    
      
    }




    .input-textsearch {
      margin-top: -2.75px;
    }
  
    .product-grid{
      grid-template-columns: repeat(auto-fill, minmax(142.5px, 1fr));
      gap: 5px;
    }
  
    /* .input-textsearch{
      border: 2.25px solid transparent;
      margin-top: 3px;
      width: 220px;
      height: 30px;
      padding: 2px 6px;
      font-size: 14px;
    } */
  }
  
  
  
  @media screen and (min-width: 1911px) and (max-width:2500px) and (orientation: landscape) {
    .product-list-container {
      display: flex;
      flex-direction: column;
      max-width: 1520px;
      
    }
    .product-grid{
      grid-template-columns: repeat(auto-fill, minmax(145.5px, 1fr));
      gap: 10px;
      max-width: 1920px;
    }

  }

  @media screen and (min-width: 2500px) {

    .product-list-container{
      max-width: 1920px;
    }

      .input-textsearch {
      margin-top: -2.5px;
    }
  }
  


  
  