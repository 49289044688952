.steps-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;  
    margin: auto;
    margin-left: 0px;
    float: right;
    text-align: right;
    margin-top: 2.5px;
  }
  
  .step {
    text-align: center;
    padding: 3.5px 7.5px;
    background-color: rgba(255, 255, 255, 0.638); /* Background color for each step */
    border-radius: 80px; /* Adjust border-radius as needed */
    box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.2); /* Add a subtle box shadow */
    display: flex;
    flex-direction: row;
    line-height: 32px;
    color: black;
    position: relative;  
  }
  
  .step:nth-child(1) .step-number {
    background-color: var(--1st-color);
  }
  
  .step:nth-child(3) .step-number {
    background-color: var(--2nd-color);
  }
  
  .step:nth-child(5) .step-number {
    background-color: var(--3rd-color);
  }
  
  .step:nth-child(7) .step-number {
    background-color: var(--4th-color);
  }

  .step-number {
    font-size: 18px;
    font-weight: bold;
    color: white; /* Color for step number */
    border: 4px solid rgba(255,165,71,255); /* Outline color for step number */
    border-radius: 50%; /* Create a circle */
    width: 32px; /* Adjust the width as needed */
    height: 32px; /* Adjust the height as needed */
    line-height: 32px; /* Vertically center the number */
    color: black;
    position: absolute;
    left: 0%;
    top:0%;
    background-color: rgba(255,165,71,255);
    border: 3px solid white;
    color: white;
    box-shadow: 3px 0px 4px rgba(0, 0, 0, 0.6); 
  
  
  }
  
  .steps-container .step-text {
    font-size: 16px;
    font-weight: 500;
    color: white; /* Text color for step description */
    color: black;
    width: max-content;
    margin-left: 44px;
  }
  
  .arrowIcon{
    margin-top: 12px;
  }
  

  @media screen and (orientation: portrait) {
    .steps-container{
      display: none;
    }
    }

    @media screen and (orientation: landscape) and (min-width:1200px) {
        .steps-container{
            display: none;
          }
    }
    
    
    
    @media screen and (min-width: 1600px) and (orientation: landscape) {
      .steps-container {
      margin-top: 12px;
      margin-left: 2.5px;
      display: none;
      height: 32px;
      }

      .step-number{
        font-size: 12.5px;
        line-height: 25px;
        height: 25px;
        width: 25px;
      }
      
      .steps-container .step-text{
        font-size: 11.5px;
        height: 32px;
        margin-left: 40px;
        margin-right: 6px;
      }
      .step{
        padding: 0.75px 3px;  
      }
      .arrowIcon{
        margin-top: 2.5px;
        margin-right: -5.5px;
        margin-left: -0.5px;
      }
    
    }
    
    @media screen and (min-width: 1900px) and (orientation: landscape) {
        .steps-container {
            margin-top: 35px;
            margin-left: -42.5px;
            display: flex;
            height: 42px;
            }
      .step-number{
        margin-top: 1.5px;
      font-size: 14.5px;
      line-height: 34px;
      width: 34px;
      height: 34px;
    }
    .arrowIcon{
        margin-top: 8px;
        margin-right: -5.5px;
        margin-left: -0.5px;
      }
      .steps-container .step-text{
      font-size: 13.5px;
      margin-left: 45px;
    }
    .step{
      padding: 4px 6px;  
    }
    }