
.heroAboutWrapTitle{
    position: relative;
    height: 9rem;
    width:300px;
    margin-left: -8rem;
}

.heroAboutWrapTitle h1{
    position: absolute;
    font-weight: 300;
    font-size: 5rem;
    bottom: 0%;
}

.heroAboutWrapTitle h2{
    position: absolute;
    font-weight: 200;
    font-size: 3rem;
    top: 12%;
    right: 8%;
}

.heroWrap .heroAboutLinks .column{
    display: flex;
    flex-direction: row;
    margin-top: 0.25rem;
}

.column .iconWrap{
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    padding: 4px;
    background-color: white;
    border: 3px solid transparent;
    background-image: linear-gradient(white, rgb(255, 255, 255), linear-gradient(315deg, rgb(236, 183, 37), rgb(250, 94, 94), rgb(255, 222, 160), rgb(250, 94, 94)));
    background-origin: border-box;
    background-clip: padding-box, border-box;
}

.column .iconWrap .icon{
    display: flex;
    margin: auto;
    line-height: 1.5rem;
    vertical-align: middle;
    margin-top: 2px;
    height: 1.2rem;                    
    width: 1.2rem;
    color: #fc8444 ;
    color: black;
}

.column span{
    line-height: 2rem;
    vertical-align: middle;
    font-weight: 500;
    margin-left: 0.2rem;
}

@media screen and (orientation: portrait) and (min-width: 300px) {

.heroWrap .videoElement{
    display: none;
}

.heroWrap .heroAboutWrapTitle{
    display: none;
}

.heroWrap .heroAboutWrapText{
    font-size: 1.8vh;
    padding: 0.55rem;

}

.heroWrap{
    position: relative;
    height: 95vh;
}

.heroWrap .heroAboutLinks{
    display: none;
    
    flex-direction: column;
    position: absolute;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.416);
    color: white;
    bottom: 0px;
    padding-bottom: 60px;
    margin-top: 50px;
    left: 0%;
    font-size: 1rem;
    font-weight: normal;
}

}

@media (min-width: 600px) and (max-width: 1024px), 
       (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) {
.heroWrap{
    display: flex;
    flex-direction: row;
}

.heroWrap .videoElement{
    display: flex;
    flex: 1;
    width: 15rem;
    height: 30rem;
    padding: 1rem;
}

.heroWrap .heroAboutWrapText{
    padding: 1rem;
    padding-top: 3rem;
    font-size: 1rem;
}
}

@media (min-width: 1200px) {


.heroWrap{
    width: 95%;
    margin: auto;
    margin-top: 0.75rem;
    display: flex;
    flex-direction: row;
}
.heroWrap .videoElement{
    width: 14rem;
    height: 25rem;
    margin-right: 2rem;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); 
}

.heroWrap .heroTextWrap{
    display: flex;
    flex-direction: column;
    margin-left: 8rem;
    font-size: 0.85rem;
}
.heroWrap .heroAboutLinks{
    margin-top: -2rem;
}
}

@media screen and (min-width: 1600px) and (orientation:landscape) {
.heroWrap{
    margin-top: 1rem;
    width: 95%;
}

.heroWrap .videoElement{
    width: 16rem;
    height: 27.5rem;
}
.heroWrap .heroAboutLinks{
    margin-top: 1rem;
}
}
@media screen and (min-width: 1920px) and (orientation:landscape) {
    .heroWrap{
        width: 82.5%;
        margin: auto;
        margin-top: 3rem;
        display: flex;
        flex-direction: row;
    }
    .heroWrap .videoElement{
        width: 18rem;
        height: 32rem;
        margin-right: 2rem;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); 
    }
}


@media screen and (min-width: 2500px) and (orientation:landscape) {
.heroWrap{
    margin-top: 7vw;
    width: 70%;
}
.heroWrap .videoElement{
    width: 20rem;
    height: 35rem;
}
}


@media screen and (min-width: 1340px) and (max-width: 1559px) and (orientation: landscape) {
    .heroWrap .heroAboutLinks{

        display: none;
    }
}