.finishOrderWrap{
    height: 100vh;
    padding-bottom: 2rem;
    background-image: url("../../media/backgroundImg/finishOrderBG3.svg"); 
    background-attachment: fixed;
    background-size: cover;
}

.finishOrderWrapper{

    min-height: 77.25vh;
    margin-bottom: 0px;
    padding-bottom: 0px;
    width: 100vw;

    display: flex;
    justify-content: center;
    align-items: center;
 
    h2{
        margin-top: 0px;
        color: black;
        font-weight: normal;
       text-align: center;
    }


    .verifyButton{
        width: 380px;
        padding: 10px;
        background-color: #2879fe;
        color: white;
        border: 1px solid #2879fe;
        cursor: pointer;                    
        font-weight: 500;
        font-size: 16px;
        transition: all 0.3s ease-in;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        margin-bottom: 20px;
        margin: auto;
        margin-top: 25px;
       

        &:hover{
            background-color: white;
            color: #2879fe;
        }

        
    }

    .backButton2{
        position: absolute;
        top: 110px;
        left: 19.5vw;
        width: 80px;
        justify-content: space-between;
        display: flex;
        background-color: #2879fe;
        color: white;
        border: 1px solid #2879fe;
        cursor: pointer;                    
        font-weight: 500;
        transition: all 0.3s ease-in;
        padding: 10px;
        padding-right: 20px;
        text-transform: uppercase;
        z-index: 99999;
        font-size: 18px;
        margin-left: -55px;

        &:hover{
            cursor: pointer;
            background-color: white;
            color: #2879fe;
        }
    }
}

@media screen and (min-width: 300px) and (max-width:767px) and (orientation: portrait) {
    .finishOrderWrap{
        background-image: url("../../media/backgroundImg/cart-tabletBG.svg"); 
        height: 100vh;
        margin-bottom: -20px;
    }
    
    .finishForm h2{
     padding-top: 0.5rem;
    }
    .finishOrderWrapper{
        align-items: flex-start;
        padding-top: 0px;
        h2{
            margin-top: 10px;
        }
        .verifyButton{
            margin-top: 0px;
            gap: 7.5px;
            font-weight: 00;
          
            width: 100%;
        }
        .backButton2{
            top:1px;
            height: 27px;
            background-color: transparent;
            border: 1px solid #2879fe;
            color: #2879fe;
            left: 200px;
            z-index: 9999999;
            display: none;
        }
    }


}

@media screen and (min-width:768px) and (orientation: portrait) {
    .finishOrderWrap{
    background-image: url("../../media/backgroundImg/cart-tabletBG.svg"); 
    .finishForm h2{
        padding-top: 6.5rem;
       }
    .finishOrderWrapper{
        min-height: 76.5vh;
        align-items: flex-start;

        .backButton2{
            display: none;
            top:62.5px;
            left: 67.5px;
        }
    }
}
}