.product-cell {
    border: 1.5px solid darkgray;
    padding: 8px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.54);
    position: relative;
    border-radius: 5px;
    transition: all 0.3s ease-in;

  }
  
  .product-cell:hover {
    cursor: pointer;
    border: 1.75px solid #2879fe;
    color: #2879fe;
    background-color: whitesmoke;

  }
  
  .product-cell .product-img {
    margin-top: 25px;
    background-color: rgb(255, 255, 255);
    border: 1px solid lightgray;
    margin-bottom: 5px;
    width: 100%;	
    padding: 0px;
    border-radius: 8px;


    
  }
  
  .product-cell img {
    max-width: 95%;
    height: 115px;
    object-fit:cover;
    border-radius: 8px;
    margin-top: 0px;
    padding-top: 10px;
    margin-bottom: 6px;
  }
  
  .product-cell .product-info .product-name {
    font-weight: 500;
    font-size: 16px;
    width: 98%;
    margin: auto;
    overflow: hidden;
    min-height: 3.6em; /* Previously $lineHeight * 3, assuming $lineHeight is 1.2em */
    max-height: 3.6em; /* Previously $lineHeight * 3, assuming $lineHeight is 1.2em */
    line-height: 1.2em; /* Previously $lineHeight */
  }
  
  .product-cell .product-info .product-category {
    background: black;
    color: white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    font-size: 12px;
    font-weight: 500;
    height: 25px;
    line-height: 25px;
  }
  
  @media screen and (min-width: 300px) and (max-width: 767px) and (orientation: portrait) {
    .product-cell {
      border: 1.5px solid darkgray;
      border-radius: 5px;
      margin-top: 5px;
      padding-top: 2px;
    }
  
    .product-cell:hover {
      border: 1.5px solid darkgray;
    }

    .product-img{
      margin-top: 2px;
      padding-top: 2px;
    }
  
    .product-cell .product-img img {
      max-width: 100%;
      height: 60px;
      padding-top: 2px;

      margin-top: 5px;
    }
  
    .product-cell .product-info .product-category {
      font-size: 9.5px;
      height: 12.5px;
      line-height: 13px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      background: black;
      color: white;
    }
  
    .product-cell .product-info .product-name {
      font-size: 11px;
      width: 100%;
      text-transform: uppercase;
    }
  }
  
  @media screen and (min-width: 768px) and (orientation: portrait) {
    .product-cell {
      border: 1.5px solid darkgray;
      border-radius: 5px;
      margin-top: 10px;
    }
  
    .product-cell:hover {
      border: 1.5px solid darkgray;
    }
  
    .product-cell .product-img img {
      max-width: 100%;
      height: 80px;
      object-fit: fill;
    }
  
    .product-cell .product-info .product-category {
      font-size: 10px;
      padding-top: 0;
    }
  
    .product-cell .product-info .product-name {
      font-size: 11.25px;
      width: 100%;
      text-transform: uppercase;
    }
  }
  
  @media screen and (min-width: 1591px) and (max-width: 1910px) and (orientation: landscape) {
  
    .product-cell:hover {
      border: 1px solid #2879fe;
    }
  
    .product-cell .product-info .product-name {
      font-size: 13px;
    }
  
    .product-img img {
      max-width: 95%;
      height: 105px;
    }
  
    .product-cell .product-info .product-category {
      font-size: 10.25px;
    }
  }
  
  @media screen and (min-width: 1911px) and (max-width: 2500px) and (orientation: landscape) {
    .product-cell .product-info .product-name {
      font-size: 14px;
    }

    .product-cell:hover {
      border: 1px solid #2879fe;
    }
  
    .product-img img {
      max-width: 95%;
      height: 105px;
    }
  
    .product-category {
      font-size: 10px;
    }
  }
  