:root {
    --blue-color: #2879fe;
  }

.navbarWrapper{
position: relative;
}

.phone-only{
    display: none;
}

.desktop-only{
    position: block;
}

.menuCenter{
    max-width: 88%;
    width: 88%;    
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.navbar-menu{
    background-color: #f0f0f0; /* Set your background color */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); 
    height: 3.25rem;
    width: 100%; 
    z-index: 9999999;
    line-height: 3.5rem;
    position: fixed;
    width: 100%;
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.navbar-menu .titleWrap{
    position: relative;
    width: 8rem;
    height: 2.5rem;
    flex: 2;
}

.navbar-menu .titleWrap h1{
    position: absolute;
    font-weight: 400;
    font-size: 1.75rem;
    top: -85%;
    left: 10%;
}

.navbar-menu .titleWrap h2{
    position: absolute;
    font-weight: 300;
    font-size: 1.25rem;
    top: -18.5%;
    left: 40%;
}

.navbar-menu header{
    width: fit-content;
    display: flex;
    flex-direction: row;
    display: flex;
    float: right; 
    height: inherit;
    gap: 2.5rem;
    padding-right: 5rem;
}

.navbar-menu header .link span{
    font-weight: 400;
    font-size: 1.1rem;
    flex: auto;
    text-align: center;
    transition: all 60ms ease-in-out;
    font-weight: 500;
}

.navbar-menu header .link span:hover{
    color: var(--blue-color);
}

.navbar-menu header .cartIconWrap{
position: relative;
}

.navbar-menu header .cartIconWrap .cartIcon{
    height: 2rem;
    width: 2rem;   
    display: flex;
    vertical-align: middle;    
    margin-top: 40%;
}

.cartIconWrap span{
    width: 1.3rem;
    height: 1.3rem;
    border-radius: 50%;
    background-color: var(--blue-color);
    color: white;
    position: absolute;
    right:-20%;
    top: 5%;
    display: flex;
    align-items: center;
    justify-content: center;    
    text-align: center;
    font-size: 0.75rem;
}

.cartIconWrap:hover{
    cursor: pointer;
}


@media screen and (orientation: portrait) and (min-width: 300px) {
.phone-only{
    display: block;
}

.desktop-only{
    display: none;
}

.dropdown-menu-container{
    display: flex;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999999;
    margin-bottom: 3.25rem;
    height: 3.25rem;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); 

}

.dropdown-menu-container .titleWrap{
    position: relative;
    width: 7rem;
    height: 2.5rem;
}


.dropdown-menu-container .titleWrap h1{
    position: absolute;
    font-weight: 400;
    font-size: 1.75rem;
    top: -60%;
    left: 5%;
}

.dropdown-menu-container .titleWrap h2{
    position: absolute;
    font-weight: 300;
    font-size: 1.5rem;
    top: 5%;
    left: 30%;
}

.dropdown-menu-container .menu-items{
    width: 5.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 0.5rem;
}

.dropdown-menu-container .menu-items .cartIconWrap{
    height: inherit;
    position: relative;
}

.dropdown-menu-container .menu-items .cartIconWrap .cartIcon{
    height: 2rem;
    width: 2rem;   
    display: flex;
    vertical-align: middle;    
    margin-top: 30%;
}

.dropdown-menu-container .menu-items .buttonWrap{
    height: inherit;
    position: relative;    
}

.dropdown-menu-container .menu-items .menuWrap{
    height: inherit;
    position: relative;
}

.dropdown-menu-container .menu-items .menuWrap .menuWrapIcon{
    width:2.25rem;
    height: 2.25rem;
    margin-top: 20%;
}

.dropdown-menu-opened{
    width: fit-content;
    display: block;
    z-index: 999;
    position: absolute;
    right: 0%;
    top: 3.25rem;
    background-color: #ededed;
    border: 1px solid lightgray;
}

.dropdown-menu-opened .headerItem{
    height: 1.2rem;
    line-height: 1.2rem;
    box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.2); /* Add a subtle box shadow */
    text-transform: capitalize;
    padding: 0.8rem;
}

.dropdown-menu-opened .headerItem span{
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    text-transform:uppercase;
    color: var(--blue-color);
}
}

/* @media screen and (min-width: 1530px) and (max-width: 1910px) and (orientation: landscape) {
    .menuCenter{
        max-width: 100%;
        width: 100%;    
        margin: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0px;
    }
    
    .navbar-menu{
        background-color: #f0f0f0; 
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); 
        height: 3.25rem;
        width: 100%; 
        z-index: 9999999;
        line-height: 3.5rem;
        position: fixed;
        width: 100%;
        top: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0px;

    }
    
} */