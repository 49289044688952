// Your existing styling for the form container
.finishOrderFormWrapper {
    // Add any additional styling for the form container here
    width: max-content;
    margin: auto;
    text-align: left;
    background-color: whitesmoke;
    padding: 20px;
    display: flex;
    margin-top: 10px;
    box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.1);
    width: 550px;

  }
  
  form {
    // Add general styling for the form here
    justify-content: center;
  }
  
  // Styling for the form rows
  .form-row {
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-bottom: 25px;
    width: 100%;



  }
  
  // Styling for form groups
  .form-group {
    margin-bottom: 15px;
  
    .nameInput{
        width: 250px;
        font-size: 18px;
    }

    #comment {
      width: 100%;
      box-sizing: border-box; // Include padding and border in the element's total width and height
      font-family: Arial, Helvetica, sans-serif;
      font-size: 11pt;
      padding: 10px;
    }

    .nameInput2{
        width: 280PX;
        font-size: 18px;
    }

    // Style labels
    label {
      font-weight: bold;
    }
  
    // Style input elements
    input {
      width: 100%;
      padding: 8px;
      box-sizing: border-box;
      border: 1px solid #ced4da; // Bootstrap's default input border color
      border-radius: 4px;
    

    }

 
  
    // Style error messages
    .alert.alert-danger {
      margin-top: 5px;
      font-size: 14px;
      color: #721c24; // Bootstrap's default alert-danger text color
      background-color: #f8d7da; // Bootstrap's default alert-danger background color
      border: 1px solid #f5c6cb; // Bootstrap's default alert-danger border color
      border-radius: 4px;
      padding: 8px;
    }
  }
  
  // Styling for the button
  .finishOrder {
    width: 410px;
    padding: 10px;
    background-color: #2879fe;
    color: white;
    border: 1px solid #2879fe;
    cursor: pointer;                    
    font-weight: 500;
    font-size: 16px;
    transition: all 0.3s ease-in;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
    margin: auto;
    margin-top: 25px;
   
    .checkIcon{
      width: 32px;
      height: 32px;
      margin: 0px;
    }

    &:hover{
        background-color: white;
        color: #2879fe;
    }
    span{
        line-height: 50px;
        font-size: 18px;
        font-weight: 500;
        vertical-align: middle;
    }

  
  }
  
  // Customize the styling for the CheckIcon
  // Add your specific styling for the CheckIcon here (if needed)
  
  @media screen and (min-width: 300px) and (max-width:767px) and (orientation: portrait) {
.finishOrderFormWrapper{
  width: 100%;
 padding: 0px;
  padding-bottom: 0px;
  margin-bottom: 0px;    display: flex;
  justify-content: left;

  .form-group{
    padding: 5px;
    margin-right: 0px;
    margin-left: -5px;

    .nameInput{
      width: 97.5%;
    }
    .nameInput2{
      width: 97.5%;     
    }
    input{
      width: 97.5%;
    }
    gap: 0px;

    .textarea-container {
    
      // Set the number of rows and columns
      textarea {
        rows: 4; // Set the number of rows
        cols: 20; // Set the number of columns
        // Add any other styling properties as needed
        resize: none; // Disable textarea resizing, if desired
      }
    }
  }

    .finishOrder span{
      margin-top: -12.5px;
    }

  .form-row{
    gap: 2px;
    width: 95vw;
    margin: auto;
    display: flex;
    justify-content: left;
  }




  .finishOrder{
    width: 100vw;
    margin-bottom: 0px;
    margin-top: 0px;
    padding-bottom: 0px;
    .checkIcon{
      display: none;
    }
  }
  
}
  }