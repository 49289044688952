/* Main Wrapper Styles */
.termsAndConditionsInfoWrapper {
    max-width: 700px;
    margin: auto;
    
    padding-bottom: 70px;
  }
  
  /* Title Styles */
  .termsAndConditionsInfoWrapper h1 {
    margin: auto;
    font-weight: 400;
    margin-top: 30px;
    text-align: center;
  }
  
  /* Horizontal Rule Styles */
  .termsAndConditionsInfoWrapper hr {
    width: 50%;
    margin-bottom: 50px;
    margin-top: 5px;
  }
  
  /* Terms Container Styles */
  .termsAndConditionsInfoWrapper .terms-container {
    font-family: 'Arial', sans-serif;
    max-width: 800px;
    margin: 0 auto;
  }
  
  /* Section Styles */
  .termsAndConditionsInfoWrapper .section {
    margin-bottom: 20px;
  }
  
  /* Section Heading Styles */
  .termsAndConditionsInfoWrapper .section .section-heading {
    color: #333;
    font-size: 1.25rem;
    margin-bottom: 10px;
  }
  
  /* Paragraph Styles */
  .termsAndConditionsInfoWrapper .section p {
    color: #666;
    line-height: 1.5;
    font-size: 0.8rem;
  }
  
  /* Media Query for small screens in portrait orientation */
  @media screen and (min-width: 300px) and (orientation: portrait) {
    .termsAndConditionsInfoWrapper {
      margin-top: 0px;
      max-width: 95vw;
      padding: 20px;
      padding-top: 0px;
    }
  }
  