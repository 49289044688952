body{
    background-color: #d5d5d5;
    }
    
    @media screen and (min-width: 1200px) and (orientation: landscape) {
    
    .productsWrap{

        background-image: none;
        background-attachment: fixed;
        background-size: cover;
        min-height: 100vh;
        width: 100vw;

    
    }
    
    .productsWrap2{
        background-attachment: fixed;
        background-size: cover;
 
        background-image: none;
        min-height: 100vh;
        width: 100vw;
    
    }
    
    }
    .scroll{
        overflow-y: clip;
    }
    
    .no-scroll{
        height: 100vh;
        overflow-y: hidden;
    }
    
    @media screen and (min-width: 300px) and (orientation: portrait) {
        .productsWrap{
            background-image: none;
        }
        .productsWrap2{
            background-image:none;
        }
    }
    
    
    @media screen and (min-width: 1350px) and (max-width:1910px) and (orientation: landscape) {
        .productsWrap{
            background-image: url("../../media/backgroundImg/productsBG-left-1600.svg"); 
        }
        .productsWrap2{
            background-image: url("../../media/backgroundImg/productsBG-right-1600.svg");  
        }
    }
    
    @media screen and (min-width: 1910px) and (orientation: landscape){
        .productsWrap{
            background-image: url("../../media/backgroundImg/productsBG-left4.svg");
        }
        .productsWrap2{
            background-image: url("../../media/backgroundImg/productsBG-right2.svg");  
        }
    }