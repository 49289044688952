

.productsTableWrapper{
    min-width: 1050px;
    max-width: fit-content;
    margin: auto;
    border-radius: 2px;
    margin-top: 0px;
    overflow: hidden;
    box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.5);
    padding-top: 1rem;
    padding: 1rem;
    background-color: rgb(250, 250, 250);

}
.productTable {
  width: 95%;
  margin: auto;
  border-radius: inherit;
 
  max-height: 815px;
  display: block;
  overflow-y: auto;

  th{
    
    background-color: #d5d5d5;

    font-weight: normal;
    text-transform: uppercase;
    font-size: 0.8rem;
  }

    th, td {
      text-align: center;
      margin-left: 10px;
      margin-right: 10px;
    }


    thead tr{
      width: 100%;
        height: 40px;
        color: black;
        border: 3px solid black;
       

    }
  

    
    tbody tr {
      height: 40px;
      margin: auto;
      &:nth-child(even) {
        background-color: white; // Apply even-row color
      }

      &:nth-child(odd) {
        background-color: whitesmoke; // Apply odd-row color
      }

      &:hover {
        background-color: #f8f9fa; // Light background on hover
      }

      transition: all 1s ease-in-out;
   


          .image {
            min-width: 50px;
            margin: auto;
            padding-top: 0px;
            width: fit-content;
            text-align: center;
            height: 50px;
    
            padding-bottom: 0px;
            margin-bottom: 0px;
      
            .imageTable{
              width: 50px;
              height: 50px;
              object-fit:cover;
              margin-top: 3.5px;

            }
      
          }

        .name{
          min-width: 550px;
          text-align: left;
            padding-left: 20px;
            font-size: 1rem;
        }

        .category{
          font-weight: 500;
          font-size: 14px;
          color: #2879fe;
          padding: 5px;
          width: 200px;
        }

        .quantity{
          width: fit-content;
          padding: 0px 3.5px;
          display: flex;
          flex-direction: row;
          margin: auto;
          text-align: center;
          margin: auto;

          .quantityWrapper{
            display: flex;
            margin: auto;

            span{
              line-height: 50px;
              vertical-align: middle;
              font-size: 1rem;
              margin-top: 5px;
              padding-left: 10px;
              padding-right: 10px;
            }
  
            .quantityButton{
              line-height: 50px;
              vertical-align: middle;
              width: 30px;
              height: 30px;
              font-size: 20px;
              padding: 10px;
              background-color: #2879fe;
              color: white;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 20px;
              cursor: pointer;
              border: none;
              font-weight: 500;
              margin-top: 15px;
              border: 1px solid #2879fe;
              transition: all 0.3s ease-in;
              &:hover{
                background-color: white;
                color: #2879fe;
              }
            }
  
            .quantity-disabled{
              background-color: gray;    
              cursor: default;
              &:hover{
                background-color: gray;
                color: white;
              }
          }
          }


  
        }

        .delete{
            color: red;
            font-size: 30px;
            cursor: pointer;
            transition: all 0.3s ease-in;
            &:hover{
                color: #2879fe;
            }
        }
    }

  }
  
  @media screen and (min-width: 320px) and (max-width:767px) and (orientation: portrait) {
    .productsTableWrapper{
      min-width: 100vw;
      max-width: 100vw;
      background-color: rgba(255, 255, 255, 0);

      z-index: 1;
      padding-top: 0px;
      padding: 0rem;

      .productTable{
       width: 100%;
       background-color: rgba(255, 255, 255, 0);
        padding-top: 0px;
        thead tr th{
          &:nth-child(3){
            display: none;
          }
        }

        tbody tr td{
          &:nth-child(3){
            display: none;
          }
        }
        thead tr{
          width: 100%;
            height: auto;
          }


        tbody tr {
         
          .name{
            min-width:auto;
            width: 290px;
            font-size: 0.8rem;
            padding-left: 5px;
            padding-right: 2px;
          }
          .quantity{
            vertical-align: middle;
            align-items: center;
            align-self: center;
            padding-left: 5px;
            padding-right: 5px;
            margin-top: 5px;
            width: fit-content;
            min-width: fit-content;
            .quantityButton{
              width: 16px;
              height: 16px;
              line-height: 16px;
            }
            span{
              line-height: 42px;
              padding-left: 4px;
              padding-right: 4px;
            }
          }
          .image{
            min-width: 32px;
            width: fit-content;
            padding: 0px 5px;
            border: 1px solid white;
            .imageTable{
              width: 36px;
              min-width: 32px;
              padding: 0px;
            }
          }
          .delete{
            width: 22px;
            margin-top:10px;
          }

        }
      }
    }
  }

@media screen and (min-width:768px) and (orientation: portrait) {
  .productsTableWrapper{
    min-width: 100vw;
    max-width: 100vw;
    padding: 0rem;
    padding-top: 1rem;
    .productTable{
      tbody tr {
        .name{
          min-width:auto;
          width: 290px;
          font-size: 16px;
        }
      }
    }
  }
}