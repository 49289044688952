:root {
  --blue-color: #2879fe;
  --1st-color: #ffc752;
  --2nd-color: #ffa547;
  --3rd-color: #fe8247;
  --4th-color: #f75e4f;
}

.parentDiv {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  min-height: 100vh; /* Minimum height of the entire viewport */
}
.parentDiv .pageWrapper{
  display: flex;
  flex: 1; /* Allows this div to grow and fill available space, pushing the footer down */
  flex-direction: column;
  position: relative;
  min-height: 100vh;
}

.parentDiv nav{
  position: fixed;
  top: 0;
  width: 100%;
  height: 3.25rem; 
  z-index: 10000000; 
  background-color: #ededed;

}
.parentDiv .bodyContent{
  flex: 1;
  padding-top: 3.25rem; 
  width: 100%;
  box-sizing: border-box;

}

/* body{
overflow-x: hidden;
} */

html{
  width: 100vw;
  overflow-x: hidden;

}

.scroll{
  overflow-y: clip;
}

.phone-only{
  display: none;
}

.desktop-only{
  position: block;
}

.no-scroll{
  height: 100vh;
  overflow-y: hidden;
}


@media screen and (orientation: portrait) and (min-width: 300px) {
  .phone-only{
      display: block;
  }
  
  .desktop-only{
      display: none;
      z-index: -9999999;
      background-color: red;
  }
 
}

@media (max-width: 599px) {
  html {
    font-size: 14px; /* Smaller base size for smaller devices */
  }
}

/* Tablets (600px and up), but not iPad Pro */
@media (min-width: 600px) and (max-width: 1024px), 
       (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) {
  html {
    font-size: 16px; /* Base size for non-iPad Pro tablets */
  }
}

/* iPad Pro (10.5-inch, 11-inch, and 12.9-inch) */
@media (min-width: 1024px) and (max-height: 1366px) and (orientation: landscape), 
       (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) {
  html {
    font-size: 17px; /* Custom font size for iPad Pro */
  }
}

/* Desktops (larger than 1200px) */
@media (min-width: 1200px) {
  html {
    font-size: 18px; /* Slightly larger base size for larger screens */
  }
}

@media (min-width: 1200px) {
  html {
    font-size: 18px; /* Slightly larger base size for larger screens */
  }
}
/* Use the variables */

::-webkit-scrollbar {
  width: 10px; /* Adjust the width as needed */
}

/* Handle for both vertical and horizontal scrollbar */
::-webkit-scrollbar-thumb {
  background-color: #555; /* Color of the scrollbar handle */
  border-radius: 5px; /* Rounded corners */
}

/* Track for both vertical and horizontal scrollbar */
::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color of the scrollbar track */
}

/* Handle on hover state */
::-webkit-scrollbar-thumb:hover {
  background-color: #888;
  
}

.gradientOutline{
  border: 0.5px solid transparent;
  padding-top: -5px;
  background-image: linear-gradient(white, rgb(255, 255, 255)), linear-gradient(315deg, rgb(236, 183, 37), rgb(250, 94, 94), rgb(255, 222, 160), rgb(250, 94, 94));
  background-origin: border-box;
  background-clip: padding-box, border-box;
}


.link{
  text-decoration: none;
  color: inherit;
}
