.infoPanelWrapper {
    margin: auto;
    max-width: 90%;
    margin-top: 25px;
    margin-bottom: 30px;
  }
  
  .infoPanelWrapper .columnsWrapper {
    width: 100%;
    display: flex;
    margin: auto;
  }
  
  .infoPanelWrapper .columnsWrapper .column {
    flex-direction: row;
    width: max-content;
    margin: auto;
  }
  
  .infoPanelWrapper .columnsWrapper .column span {
    line-height: 45px;
    vertical-align: middle;
    font-size: 1vw;
    font-weight: 500;
    text-align: center;
    margin-top: 5px;
  }
  
  .infoPanelWrapper .columnsWrapper .column .iconWrap {
    border-radius: 50%;
    width: 42px;
    height: 42px;
    padding: 8px;
  }
  
  .infoPanelWrapper .columnsWrapper .column .iconWrap .icon {
    display: flex;
    margin: auto;
    line-height: 40px;
    vertical-align: middle;
    margin-top: 0px;
    height: inherit;
    width: inherit;
    color: black;
  }
  
  @media screen and (min-width: 300px) and (orientation: portrait) {
    .infoPanelWrapper {
      max-width: 100vw;
    }
  
    .infoPanelWrapper .columnsWrapper {
      flex-direction: column;
      
      width: 100%;
      margin-top: -20px;
    }
  
    .infoPanelWrapper .columnsWrapper .column {
        display: flex;
      flex-direction: row;
      width: 100vw;
      margin-left: 0.25rem;
      margin-bottom: 0.25rem;
    }
  
    .infoPanelWrapper .columnsWrapper .column .iconWrap {
      width: 18px;
      height: 18px;
      border: 1px solid transparent;
      margin-left: 5px;
      padding: 4px;

    }
  
    .infoPanelWrapper .columnsWrapper .column span {
      font-size: 1.5vh;
      line-height: 20px;
      margin-left: 0.5rem;
    }
  }
  
  @media screen and (min-width: 768px) and (orientation: portrait) {
    .columnsWrapper{
        display: grid;
        grid-template-columns: (1fr 1fr 1fr);
        width: 100vw;
    }
    .infoPanelWrapper .columnsWrapper .column {
      display: flex;
      width: max-content;
      flex-direction: column;
      justify-content: center; 
      align-items: center;
      margin-right: 10px;
      justify-content: space-evenly;
      margin: auto;
      margin-top: 15px;

    }
  
    .infoPanelWrapper .columnsWrapper .column .iconWrap {
      width: 1.75rem;
      height: 1.75rem;
      padding:6px;


    }
  
    .infoPanelWrapper .columnsWrapper .column span {
        text-align: left;
      font-size: 1rem;
      line-height: 1rem;
      margin-bottom: 1rem;
      justify-self: left;
    }
  }
  
  @media screen and (min-width: 1200px) and (orientation: landscape) {
    .infoPanelWrapper .columnsWrapper {
      width: 90%;
      margin: auto;
      margin-left: 2vw;
      margin-top: 1.5vw;
      margin-bottom: 1.5vw;
    }
  
    .infoPanelWrapper .columnsWrapper .column {
      width: max-content;
      margin: auto;
      text-align: center;
      justify-content: center;
      margin-top: -15px;
      margin-right: 0px;
      display: flex;
      flex-direction: row;
    }
  
    .infoPanelWrapper .columnsWrapper .column .iconWrap {
      width: 28px;
      height: 28px;
    }
  
    .infoPanelWrapper .columnsWrapper .column span {
      font-size: 0.8vw;
      margin-top: 0.5px;
      margin-left: 0.5vw;
    }
    }
    
    /* @media screen and (min-width: 1911px) and (max-width: 2500px) and (orientation: landscape) {
    .infoPanelWrapper {
    margin-top: 30px;
    }
    
    .infoPanelWrapper .columnsWrapper {
    width: 1600px;
    margin-left: 100px;
    }
    
    .infoPanelWrapper .columnsWrapper .column {
    width: max-content;
    margin: auto;
    text-align: center;
    justify-content: center;
    margin-top: -15px;
    }
    
    .infoPanelWrapper .columnsWrapper .column .iconWrap {
    width: 35px;
    height:35px;
    }
    
    .infoPanelWrapper .columnsWrapper .column span {
    font-size: 17px;
    margin-top: 3px;
    margin-left: -3px;
    }
    }
   */