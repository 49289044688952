.howToOrderInfoWrapper {
    min-height: 70vh;
    max-width: 42vw;
    margin: auto;
   
  }
  
  .howToOrderInfoWrapper h1 {
    font-weight: normal;
    text-align: center;
  }
  
  .howToOrderInfoWrapper hr {
    width: 50%;
    margin-bottom: 30px;
    margin-top: 0;
  }
  
  .howToOrderInfoWrapper .howto-container {
    font-family: 'Arial', sans-serif;
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .howToOrderInfoWrapper .howto-container span {
    text-align: center;
    margin: auto;
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 1rem;
   
  }

  .howToOrderInfoWrapper .step-text{
    font-size: 1.15rem;
    margin-left: 2.5rem;
    padding-right: 1rem;
    padding-bottom: 2px;
  }
  
  .howToOrderInfoWrapper .section {
    margin-bottom: 30px;
  }
  
  .howToOrderInfoWrapper .section:nth-child(even) {
    margin-left: 50px;
  }
  
  .howToOrderInfoWrapper .section:nth-child(odd) {
    margin-left: 150px;
  }
  
  .howToOrderInfoWrapper .section .section-heading {
    color: #333;
    font-size: 1rem;
    margin-bottom: 5px;
    width: fit-content;
  }
  
  .howToOrderInfoWrapper .section p {
    color: #666;
    line-height: 1.5;
    margin-left: 50px;
    font-size: 14px;
  }
  
  .howToOrderInfoWrapper .step-number{
    font-size: 22px;
  }

  .howToOrderInfoWrapper .section .step2 {
    background-color: #ffa547;
  }
  
  .howToOrderInfoWrapper .section .step3 {
    background-color: #fe8247;
  }
  
  .howToOrderInfoWrapper .section .step4 {
    background-color: #f75e4f;
  }

  @media screen and (max-width: 1600px) and (orientation: landscape) {
    .howToOrderInfoWrapper .section .step-number {
      width: 30px;
      height: 30px;
      font-size: 18px;
      line-height: 28px;
      
    }
  }

  
  @media screen and (min-width: 300px) and (orientation: portrait) {
    .howToOrderInfoWrapper {
   width: 85vw;
   max-width: 85vw;
      padding: 10px;
    }
  
    .howToOrderInfoWrapper .howto-container {
      width: 100%;
    }
  
    .howToOrderInfoWrapper .section {
      margin-bottom: 30px;
      width: 95%;
      margin-left: 1px;
    }
  
    .howToOrderInfoWrapper .section ul {
      margin-left: -50px;
    }
  
    .howToOrderInfoWrapper .section .step {
      width: 75vw;
      overflow-wrap: break-word;
      border: none;
      background-color: transparent;
      border-radius: 1px;
      
    }

    .howToOrderInfoWrapper .section-heading{
      background-color: white;
    }
  
    .howToOrderInfoWrapper .section .step-number {
      width: 32px;
      height: 32px;
      font-size: 22px;
      line-height: 32px;
      
      margin-top: 9.5px;
      margin-left: 10px;
    }
  
    .howToOrderInfoWrapper .section .step-text {
      text-align: left;
      margin-left: 50px;
      line-height: 25px;
      font-size: 1rem;
      
    }
  
    .howToOrderInfoWrapper .section:nth-child(even) {
      margin-left: 0;
    }
  
    .howToOrderInfoWrapper .section:nth-child(odd) {
      margin-left: 0;
    }
  }
  
  @media screen and (min-width: 768px) and (orientation: portrait) {
    .howToOrderInfoWrapper {
      max-width: 95vw;
    }
  
    .howToOrderInfoWrapper .section:nth-child(even) {
      margin-left: 0;
    }
  
    .howToOrderInfoWrapper .section:nth-child(odd) {
      margin-left: 50px;
    }
    .howToOrderInfoWrapper .section .step-number {
      margin-top: 3px;
    }
    .howToOrderInfoWrapper .step{
      padding: 10px 10px;
    }
  }




  
  @media screen and (min-width: 1300px) and (max-width: 1910px) and (orientation: landscape) {
    .howToOrderInfoWrapper {
      max-width: 55vw;
    }
  }
  
  @media screen and (min-width: 1911px) and (max-width: 2600px) and (orientation: landscape) {
    .howToOrderInfoWrapper {
      max-width: 50vw;
      margin-top: 7.5vh;

      padding-bottom: 0px;
      margin-bottom: 0;
    }
  }
  