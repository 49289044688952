

.home .pageWrapper{
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: relative;
    background-image: url("../../media/backgroundImg/heroBG.svg"); 
    background-attachment: fixed;
    background-size: cover;
  }
  
 .home nav{
    position: fixed;
    top: 0;
    width: 100%;
    height: 60px; 
    z-index: 100; 
  }
  .home .bodyContent{
    flex: 1;
    padding-top: 60px; 
    width: 100%;
    box-sizing: border-box;
  }