.orderFinished__backGroundWrapper{
    width: 100vw;
    height: 100%;
    background-color: rgba(38, 30, 30, 0.674);
    z-index: 9999;
    position: absolute;    
    z-index: 999999999999;
    overflow-y: hidden;
    overflow-x: hidden;
}

.orderFinished__PopUpWrapper
{
    width: 40vw;
    height: max-content;
    position: absolute;
    z-index: 9999;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0; 
    margin: auto;
    text-align: center;
    vertical-align: middle;
    justify-content: center;
    background-color: white;
    padding: 20px;
}

.orderFinished__PopUpWrapper h1{
    color: black;
    font-weight: 600;
    margin: 15px 10px;
}

.orderFinished__PopUpWrapper span{
    display: block;
    margin-top: 5px;
}

.orderFinished__PopUpWrapper .icon-success{
    margin-top: 10px;
}

.continueShopping{
    border-radius: 30px;
    margin: 20px auto;
    font-weight: 600; 
    width: 380px;
        padding: 10px;
        background-color: #2879fe;
        color: white;
        border: 1px solid #2879fe;
        cursor: pointer;                    
        font-weight: 500;
        font-size: 18px;
        transition: all 0.3s ease-in;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        margin-bottom: 20px;
        margin: auto;
        margin-top: 25px;
       

        &:hover{
            background-color: white;
            color: #2879fe;
        }
}

@media screen and (min-width: 320px) and (max-width:767px) and (orientation: portrait) {
    .orderFinished__backGroundWrapper{
        height: 100vh;
        width: 100vw;
    }

    .orderFinished__PopUpWrapper{
        width: 78vw;

        .continueShopping{
            width: 75vw;
        }
    }
}

@media screen and (min-width: 768px) and (orientation: portrait) {
    .orderFinished__backGroundWrapper{
        height: 100vh;
    }

    .orderFinished__PopUpWrapper{
        width: 70vw;
    }
}

