.carousel-container {
    display: flex;
    overflow: hidden;
    margin: auto; 
    position: relative;
    margin-top: -10px;
  }
  
  .carousel-row {
    display: flex;
    width: max-content;
    flex-wrap: nowrap;
    margin-left: -100px;
    gap: 40px;
    transition: transform 0.5s ease;
    animation: carouselAnimation 40s linear infinite;
    z-index: -1;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    background-color: rgb(255, 255, 255);
    padding-left: 350px;
    padding-right: 350px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .carousel-row img {
    margin: 0.25rem 0px;
    width: 4rem;
    height: 2rem;
    object-fit: contain;
  }
  
  @media screen and (min-width: 320px) and (max-width:767px) and (orientation: portrait) {
    .carousel-container{
      margin-top: -25px;
      margin-bottom: 5px;
    }
    
      .carousel-row{
        padding: 0px 200px;
        padding-right: 650px;
        gap: 2.25px;
        margin-left: -290px;
      }
    
      .carousel-row img {
        width: 52.25px;
        height: 19.6px;
      }
  
  }
  
  @media screen and (min-width: 768px) and (orientation: portrait) {
    .carousel-container{
      margin-top: -5px;
      margin-bottom: 5px;
    }
    
      .carousel-row{
        padding: 0px 200px;
        padding-right: 650px;
        gap: 11.25px;
        margin-left: -290px;
      }
    
      .carousel-row img {
        width: 52.25px;
        height: 25.6px;
      }
  }

  
  @media screen and (min-width: 1200px) and (max-width: 1910px) and (orientation: landscape) {
    .carousel-container{
    margin-top: -5px;
    margin-bottom: 15px;
  }
  
    .carousel-row{
      padding: 0px 380px;
      margin-left: -200px;
      gap: 17.5px;
    }
  
    .carousel-row img {
      width: 70px;
      height: 33.6px;
    }
  
  }
  
  @media screen and (min-width: 1911px) and (max-width:2500px) and (orientation: landscape) {
    .carousel-container{
      margin-top: -5px;
      margin-bottom: 15px;
    }
    
      .carousel-row{
        padding: 0px 280px;
        gap: 25px;
      }
    
      .carousel-row img {
        width: 68px;
        height: 32.6px;
      }
  }
  
  
  @keyframes carouselAnimation {
    0%, 100% {
      transform: translateX(calc(80px * 2));
    }
    50% {
      transform: translateX(calc(-80px * 6));
    }
  }