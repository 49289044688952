.currentPage{
    background-color: #2879fe;    
    color: white;
}

/* Pagination container style */
.paginationWrapper{
    height: 50px;
    width: max-content;
    margin: auto;
    margin-top: 25px;

.pagination {
    display: flex;
    list-style: none;
    padding: 0;
  }
  
  /* Style for each pagination item */
  .pagination-item {
    margin-right: 10px; /* Adjust margin between items as needed */
    font-size: 16px; /* Adjust font size as needed */
    padding: 8px 12px; /* Adjust padding as needed */
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    line-height: 20px;
    height: 20px;
    transition: background-color 0.3s;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
    background-color: white;
    &:hover {
      background-color: #f0f0f0;
    }
  

  }

  .pagination-item-active{
    background-color: #007bff;
    color: #fff;
  }
}

@media screen and (min-width: 300px) and (max-width:767px) and (orientation: portrait) {
  // .paginationWrapper{
  //   height: 30px;
  //   margin: auto;
  //   width: 80vw;
  //   display: flex;
  //   justify-content: center;
 

  //   .pagination .page-item{
  //     flex-grow: 1; /* Use flex-grow to distribute space proportionally */
  //     flex-basis: 0;
  //     padding: 0;
  //     height: 14px;
  //     line-height: 13px;
  //     font-size: 14px;
  //     margin-right: 0; /* Adjust margin as needed */
  //     border-radius: 0;
  //     gap: 0px; 
  //   }

  //   .pagination .pagination-item{
  //     margin-right: 0px;
  //     flex-grow: 1; /* Use flex-grow to distribute space proportionally */
  //     flex-basis: 0;
  //   }
  // }
  .paginationWrapper {
    width: 100%;
    display: flex;
    margin-top: -2.5px;
    margin-bottom: -12.5px;
    justify-content: center;
  }
  
  .pagination {
    list-style: none;
    display: flex;
    padding: 0;
  }
  
  .page-item {
    margin: 0;
  }

  .paginationWrapper .pagination .pagination-item{
    flex-grow: 1;
    flex-basis: 0;
    padding: 5px; /* Adjust padding as needed */
    margin-right: 6px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  
    &:hover {
      background-color: #e0e0e0; /* Adjust hover background color as needed */
    }
  }
  

  
  .pagination-item-active {
    background-color: #007bff; /* Adjust active background color as needed */
    color: #fff; /* Adjust active text color as needed */
  }

}

@media screen and (min-width: 768px) and (orientation: portrait) {
.paginationWrapper{
  height: 30px;
  .pagination-item{
    height: 14px;
    padding: 8px 8px;
    line-height: 14px;
    font-size: 12px;
    margin-right: 5.25px;

  }
}
}

@media screen and (min-width: 1600px) and (max-width:1920px) and (orientation: landscape) {
.paginationWrapper{
  height: 40px;
  margin-bottom: 25px;
  .pagination-item{
    padding: 6px 10px;
    height: 18px;
    line-height: 18px;
    font-size: 14px;
    margin-right: 5px;
  }
}
}
@media screen and (min-width: 1920px) and (max-width:2500px) and (orientation: landscape) {
.paginationWrapper{
  height: 40px;
  margin-bottom: 25px;

  .pagination-item{
    padding: 6px 10px;
    height: 18px;
    line-height: 18px;
    font-size: 14px;
   
  }
}
}