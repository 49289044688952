.auto-slider {
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  
  .auto-slider:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: -10%;
    width: 120%; /* Adjusted to 120% because left is -10% to avoid a gap */
    height: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 60%);
  }
  
  .slide {
    display: none;
    height: 42.5vh;
    position: relative;
    width: 100%;
  }
  
  .slide.active {
    display: block;
  }
  
  .background-video {
    object-fit: cover;
    width: 100%;
    height: inherit;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -2;
  }
  
  .content {
    position: relative;
    height: 100%;
    top: 20%;
    transform: translateY(-50%);
    color: white;
    overflow: hidden;
    text-align: center;
    padding: 20px;
    z-index: -1;
  }
  
  .contentText {
    position: absolute;
    left: 12%;
    top: 55%;
    text-align: left;
  }
  
  .contentText h1 {
    font-weight: normal;
    font-size: 1.7rem;
    margin-top: 40px;
    color: white;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 0px;
    -webkit-text-stroke-width: 0.75px;
    -webkit-text-stroke-color: rgb(0, 0, 0);
  }
  
  .contentText h2 {
    margin-top: 0px;
    margin-bottom: 20px;
    font-size: 4.5rem;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.861);
    margin-left: 190px;
    -webkit-text-stroke-width: 0.75px;
    -webkit-text-stroke-color: rgb(58, 58, 58);
  }
  
  .contentText button {
    padding: 10px 20px;
    background-color: #fff;
    color: #000;
    border: none;
    cursor: pointer;
    font-size: 1rem;
  }
  
  @media screen and (orientation: portrait) and (min-width: 300px) {
    .auto-slider {
      margin-top: 0px;
    }
  
    .slide {
      height: 25vh;
    }
  
    .background-video {
      height: 25vh;
    }
  
    .contentText {
      top: 35%;
      left: 2.5%;
    }
  
    .contentText h1 {
      font-size: 0.9rem;
      font-weight : 500;
      left: 4%;
      -webkit-text-stroke-width: 0px;
      -webkit-text-stroke-color: rgb(255, 255, 255);
      }
      
      .contentText h2 {
      font-size: 2.1rem;
      margin-left: 5px;
      font-weight: 600;
      -webkit-text-stroke-width: 0px;
      -webkit-text-stroke-color: rgb(0, 0, 0);
      }
      }
      
      /* TABLET */
      @media screen and (min-width: 768px) and (orientation: portrait) {
      .auto-slider {
      margin-top: 0px;
      }
      
      .slide {
      height: 30vh;
      }
      
      .background-video {
      height: 30vh;
      }
      
      .contentText {
        top:50%;
      left: 2.5%;
      }
      
      .contentText h1 {
      font-size: 1.3rem;
      left: 4%;
      }
      
      .contentText h2 {
      font-size: 2.2rem;
      margin-left: 25px;
      }
      }

      @media screen and (min-width: 1200px) and (orientation: landscape) {
        .contentText h1 {
          font-size: 1.5vh;
          }
          
          .contentText h2 {
          font-size: 6vh;
          margin-left: 20px;
          }
      }
      @media screen and (min-width: 1550px) and (orientation: landscape) {

      .contentText h1 {
      font-size: 2.5vh;
      }
      
      .contentText h2 {
      font-size: 7vh;
      margin-left: 20px;
      }
      }
      
   