.howToOrderWrap{
    background-attachment: fixed;
    background-size: cover;
    background-image: url("../../media/backgroundImg/finishOrderBG3.svg"); 
    height: max-content;
    margin-bottom: -20px;
}

@media screen and (orientation: portrait) {
 .howToOrderWrap{    background-image: none;}

}