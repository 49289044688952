.backButton{
    position: absolute;
    top: 110px;
    left: 17vw;
    width: 80px;
    justify-content: space-between;
    display: flex;
    background-color: #2879fe;
    color: white;
    border: 1px solid #2879fe;
    cursor: pointer;                    
    font-weight: 500;
    transition: all 0.3s ease-in;
    padding: 10px;
    padding-right: 20px;
    text-transform: uppercase;
    z-index: 99999;
    font-size: 18px;
    margin-left: -55px;
    &:hover{
        cursor: pointer;
        background-color: white;
        color: #2879fe;
    }
}

@media screen and (min-width: 300px) and (max-width:759px) and (orientation: portrait) {
.backButton{
    display: none;
    z-index: -1;
}
}

@media screen and (min-width:740px) and (orientation: portrait) {
.productItemDetailsWrapper .backButton{
    position: fixed;
    z-index: 1;
    margin-left: -25px;
    margin-top: -25px;
}
}

@media screen and (min-width: 1920px) and (max-width: 2500px) and (orientation: landscape) {
}

@media screen and (orientation: portrait) {
.backButton{
    top:62.5px;
    left: 67.5px;
    display: none;
}
}