.categoryDropdownWrap{  
     margin-top: -2.5px;   
     height: 45px;  
 }

 label{
    margin-right: 10px;
}

select{
    font-size: 22px;
}

select:hover{
    cursor: pointer;
}

select::selection:hover{
    cursor: pointer;
}

.subCategory{
    right: 0%;
    background-color: rgb(255, 255, 255);
    text-indent: 10px; 
    margin-left: 10em;       
    font-size: 18px; 
    text-align: left;
}

.mainCategory{
    font-weight: 700;
    font-size: 16px;
    background-color: rgb(0, 0, 0);
    color: white;
    text-transform: uppercase;

}
option{
    font-weight: 500;
}

option:hover{
    cursor: pointer;
}

 
 @media screen and (min-width: 300px) and (max-width:767px) and (orientation: portrait) {
  .categoryDropdownWrap{
     width: 49.5vw;
     height: 37.5px;
     border: 1px solid transparent; 
 }

 .linear-background-select{
    width: 49.5vw;
 }
 option{
    &:checked {
        background-color: lightblue;
        color: white;
      }
}

 
 }
 
 @media screen and (min-width: 768px) and (orientation: portrait) {
 }
 
 
 @media screen and (min-width: 1200px)  and (orientation: landscape) {
     .linear-background-select{
        border: 1px solid black;
        width: 220px;
         height: 35px;
         height: inherit;
     }
     .categoryDropdownWrap select{
         font-size: 12.5px;
     }
 
     .categoryDropdownWrap .subCategory{
         font-size: 16px;
         margin-top: 2px;
     }
 
     .categoryDropdownWrap .mainCategory{
         font-size: 18px;
 
     }
 
   }