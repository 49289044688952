.cart {
    position: absolute;
    right: 100px;
    width: 390px;
    top: 3.25rem;
    z-index: 9999;
    background-color: white;
    padding: 20px;
    -webkit-box-shadow: 3px 3px 43px -11px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 3px 3px 43px -11px rgba(0, 0, 0, 0.75);
    box-shadow: 3px 3px 43px -11px rgba(0, 0, 0, 0.75);
    height: fit-content;
    max-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
  }


  
  .cart hr {
    width: 100%;
    margin: auto;
  }
  
  .cart h2 {
    margin-bottom: 10px;
    text-align: center;
    margin-top: -10px;
    color: black;
    font-weight: 600;
    font-size: 22px;
  }
  
  .cart .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    margin-bottom: 2px;
    height: 70px;
  }
  
  .cart .item .delete {
    color: red;
    font-size: 30px;
    cursor: pointer;
    transition: all 0.3s ease-in;
  }
  
  .cart .item .delete:hover {
    color: #2879fe;
  }
  
  .cart .item img {
    width: 62.5px;
    height: 62.5px;
    object-fit: cover;
    border: 1px solid lightgray;
    padding: 1.5px;
    margin-top: 1px;
    border-radius: 10px;
  }
  
  .cart .item .details {
    height: 50px;
    width: 280px;
    position: relative;
  }
  
  .cart .item .details h3 {
    font-size: 16px;
    font-weight: 500;
    margin-top: -7px;
    text-align: left;
    line-height: 16px;
    margin-bottom: 0px;
  }
  
  .cart .item .details p {
    color: gray;
    font-size: 14px;
    text-align: justify;
    margin-top: -12px;
  }
  
  .cart .item .details .quantity {
    position: absolute;
    right: 0px;
    top: 30px;
    font-weight: 500;
    color: #2879fe;
    text-align: left;
    margin-top: -17px;
  }
  
  .cart .item .details .quantity::after {
    content: " ks";
  }
  
  .cart .total {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  .cart button {
    width: 250px;
    padding: 10px;
    background-color: #2879fe;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid #2879fe;
    transition: all 0.3s ease-in;
    margin: auto;
    margin-top: 12.5px;
  }
  
  .cart button:hover {
    background-color: white;
    color: #2879fe;
  }
  
  .cart .reset {
    color: red;
    font-size: 16px;
    cursor: pointer;
  }
  /* Styles for small devices (portrait) */
@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: portrait) {
    .cart {
      right: 0%;
      width: 100vw;
      top: 47.5px;
      padding: 0px;
      max-height: 93.5vh;
    }
  
    .cart .cartEmpty {
      padding: 20px;
      margin: auto;
      font-weight: 400;
      text-transform: uppercase;
      font-style: italic;
      text-align: center;
    }
  
    .cart .listOfItems {
      height: fit-content;
      max-height: 60vh;
      overflow: auto;
    }
  
    .cart h2 {
      padding-top: 20px;
    }
  
    .cart button {
      width: 100%;
      padding: 12px;
      font-weight: 700;
      font-size: 17px;
    }
  
    .cart .reset {
      font-weight: 700;
      text-align: center;
      display: flex;
      justify-content: center;
      margin-top: 0px;
      padding: 12px;
      color: white;
      background-color: red;
    }
  
    .cart .item {
      width: 95%;
      margin: auto;
      gap: 15px;
      margin-bottom: 0px;
    }
  
    .cart .item .details h3 {
      margin-top: -7px;
      margin-bottom: 0px;
    }
  
    .cart .item .details p {
      margin-top: 2px;
    }
  
    .cart .item .details .quantity {
      margin-top: 5px;
    }
  }
  
  /* Styles for medium devices (portrait) */
  @media screen and (min-width: 768px) and (orientation: portrait) {
    .cart {
      right: 0px;
      width: 350px;
      top: 3.25rem;
    }
  
    .cart button {
      width: 100%;
      padding: 12px;
      font-weight: 700;
      font-size: 17px;
    }
  
    .cart .reset {
      font-weight: 700;
      text-align: center;
      display: flex;
      justify-content: center;
      margin-top: 7.5px;
      border: 1px solid red;
      padding: 12px;
    }
  
    .cart .item {
      gap: 15px;
      margin-bottom: 0px;
    }
  
    .cart .item .details h3,
    .cart .item .details p {
      margin-top: -7px;
      margin-bottom: 0px;
    }
  
    .cart .item .details p {
      margin-top: 2px;
    }
  }
  