.productDetailWrapper{
    background-image: url("../../media/backgroundImg/productDetail-top3.svg"); 
    background-attachment: fixed;
 background-size: cover;
 position: relative;
 background-color: #d5d5d5;
}

@media screen and (orientation: portrait) and (min-width: 300px) {
    .productDetailWrapper{
        background-image: none;
    }
}   