.footer {
    overflow-x: hidden;
    background-color: #000000;
    color: #fff;
    padding: 1vw;
    display: flex;
    justify-content: left;
    padding-left: 0;
    width: 9vw;
    bottom: 0%;
    left: 0%;
    overflow-x: hidden;
    background-color: #000000;
    color: #fff;
    padding: 1vw 0; /* Adjusted for consistent vertical padding */
    display: flex;
    justify-content: left;
    width: 100%; 

  }
  .imageWrapper{
    margin-left: 15vw;
    padding-top: 1rem;
    margin-top: 1.5rem;

  }

  .imageWrapper img{
width: 6rem;
height: 6rem;
    min-width: 6rem;
    min-height: 6rem;
    justify-self: right;
    border-radius: 50%;
  }
  
  .middleSection {
    display: flex;
    width: 80vw;
    margin: auto;
    padding-top: 0.5rem;

  }
  
  .map {
    width: 450px;
    height: 200px;
    background-color: #333; /* Placeholder background for the map */
  }
  
  .company-data {
    max-width: 600px;
    margin-left: 40px;
  }
  
  .company-name {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .row {
    display: flex;
    gap: 12px;
    flex: 1;
    margin-bottom: 2.5px;
  }
  
  .row span {
    margin-top: 0px;
    line-height: 40px;
    vertical-align: middle;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
  }

  .row span strong{
    color: var(--3rd-color);
  }
  
  .iconWrap {
    border: 2px solid black;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    padding: 8px;
    background-color: white;
  }
  
  .iconWrap .icon {
    display: flex;
    margin: auto;
    line-height: 30px;
    vertical-align: middle;
    height: 24px;
    width: 24px;
    color: #fc8444;
    color: black;
  }
  
  /* Media Queries */
  @media screen and (min-width: 300px) and (max-width: 767px) and (orientation: portrait) {

    .productDetailWrapper .footer{
      margin-top: 35px;
    }

    .footer {
      padding: 0;
      width: 100vw;
      margin: 0;
      margin-top: 0px;
    }
  
    .footer .middleSection {
      width: 100vw;
      max-width: 100vw;
      margin-left: 10px;
      padding-top: 10px;

    }
  
    .footer .middleSection .map {
      display: none;
    }
  
    .footer .middleSection .company-data {
      width: 100vw;
      padding: 0;
      margin-left: 2px;
    }
  
    .footer .middleSection .company-name {
      font-size: 14px;
    }
  
    .footer .middleSection .row .iconWrap {
      font-size: 12px;
      width: 18px;
      height: 18px;
      border: 1px solid transparent;
     padding: 4px;
    }

    .footer .middleSection .row{
        margin-top: -6px;
    }
  
    .footer .middleSection .row .iconWrap .icon {
      width: 18px;
      height: 18px;
    }
  
    .footer .middleSection .row span {
      font-size: 0.8rem;
      margin-top: -5px;
    }
  }
  
  @media screen and (min-width: 768px) and (orientation: portrait) {
    .footer {
     width: 100vw;
    }
  
    .middleSection {
      width: 98vw;
    }
  
    .middleSection .map {
      width: 270px;
      height: 135px;
      margin-top: 10px;
    }
  
    .middleSection .company-data {
      margin-top: 5px;
    }
  
    .middleSection .company-name {
      margin-top: 0;
      font-size: 15px;
    }
  
    .middleSection .row {
      margin-bottom: 5px;
    }
  
    .middleSection .row .iconWrap {
      height: 16px;
      width: 16px;
    }
  
    .middleSection .row .iconWrap .icon {
      height: 22px;
      width: 22px;
      margin-left: -2.5px;
      margin-top: -2px;
    }
  
    .middleSection .row span {
      font-size: 12px;
    }
  }
/*   
  @media (max-width: 768px) {
    .footer {
      flex-direction: column;
      align-items: center;
    }
    .middleSection .row span {
      font-size: 10px;
    }
    .map {
      width: 100%;
    }

  
    .company-data {
      margin-top: 20px;
    }
  } */
  
  @media screen and (min-width: 1530px) and (max-width: 1910px) and (orientation: landscape) {
    .footer {
      padding: 0px;
    }
  
    .middleSection {
      width: 95vw;
    }
  
}
@media screen and (orientation: portrait) {

.productDetailWrapper .footer{
  display: none;
}
}