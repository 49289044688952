
.chat-icon-wrapper{
    position: fixed;
    width: 3.5rem;
    height: 3.5rem;
    z-index: 99999;
    right: 1%;
    bottom: 1%;
    border-radius: 50%;
    border: 3px solid white;
    background-color: red;
    display: flex;
    align-items: center;
    text-align: center;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.6); 
    transition: border-color 0.5s ease-in;
}

.chat-icon-wrapper:hover{
    border-color: var(--blue-color);
    cursor: pointer;
}

.chat-icon-wrapper .chat-icon{
    width: 2rem;
    height: 2rem;
    color: white;
    position: absolute;
    right: 20%;
    transition: all 1.6 ease-in-out;
}
  
  .chat-icon {
    /* Add styles for your chat icon here */
  }
  
  /* Container for the form, it should be hidden by default */
  .contact-form-container {
    display: block;
    position: fixed;
    bottom: 1%;
    right: 5.5rem;
    z-index: 999999991; /* Above the chat icon */

  }
    
  .contact-form {
    background: rgb(255, 255, 255);
    border-radius: 10px;
    border-top: 1px solid lightgray;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 390px;
  }
  
  /* Header styles */
  .contact-form-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .contact-form-header span {
    font-weight: bold;
    font-size: 1.2rem;
    text-align: center;
  }



  .mail-icon{
    width: 60px;
    height: 60px;
  }
  
  .close-icon {
    cursor: pointer;
    display: flex;
    width: 3rem;
    height: 3rem;
    color: darkgray;
    position: absolute;
    right: 0.5%;
    top: 0.5%;
  }
  
  .close-icon:hover{
    color: black;
  }
  /* Form body styles */
  .contact-form-body {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 10px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input,
  .form-group textarea {
    width: 94.5%; /* Full width */
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-family: Arial, Helvetica, sans-serif;

  }

  .form-group textarea{
    height: 5rem;
  }

  textarea::placeholder{
    font-family: Arial, Helvetica, sans-serif;
  }


  
  .submit-btn {
    background: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .submit-btn-text {
    /* Add styles for the submit button text if needed */
  }
  
  /* Responsive styles */
  @media (max-width: 768px) { /* Tablets and below */

    .contact-form-container {
        display: block;
        position: fixed;
        width: inherit;
        width: 100%; /* Full viewport width */
        bottom: 0%;
        right: 0%;
        z-index: 999999991; /* Above the chat icon */
      }
      .contact-form {
        padding: 35px;   
        height: fit-content; /* Full viewport height */
        border-radius: 0; /* No border radius */
        max-width: none; /* Override the max width */
      }
  }
  
  @media (max-width: 480px) { /* Phones */
    .contact-form-container{
      height: 200vh;
      background-color: rgba(41, 41, 41, 0.732);
    }
    .form-wrap-additional{
      padding: 0px 30px;
      padding-bottom: 10px;
    }
    .contact-form {
      position: absolute;
      bottom: 0px;
      width: 100%;
      padding: 0px;

        padding-top: 15px;
      /* Inherit styles from the tablet view, since they're very similar */
    }
  }
  
  @media screen and (min-width: 1530px) and (max-width:1910px) and (orientation: landscape) {

  }
