
  /* Wrapper for your components, ensuring it takes full height */
  .productItemDetailsWrapper {
    display: flex; /* Align children (left, middle, right) in a row */
    height: fit-content; /* Full viewport height */
    width: 90%;
    max-width: 1350px;
    min-height: 100vh; /* Full height of the viewport */    
    height: 100vh;
    margin: auto;
    margin-top: 6.5vw;
    
  
}
  
.backProductButton{
    width: 80px;
    height: 30px;
    background-color: #2879fe;
    color: white;
    border: 1px solid #2879fe;
    cursor: pointer;                    
    font-weight: 500;
    transition: all 0.3s ease-in;
    padding: 10px;
    padding-right: 20px;
    text-transform: uppercase;
    z-index: 99999;
    font-size: 18px;
    display: flex;
    align-items: center; /* Vertically centers the flex items */
  justify-content: center;
  margin-top: -7.5vh;
  margin-bottom: 20px;
}
.backProductButton:hover{
    cursor: pointer;
    background-color: white;
    color: #2879fe;
}
.backProductButton span{
   margin-left: 5px;
}

 .productItemDetailsWrapper .left{
    flex: 1.5;
    display: flex;
    flex-direction: column;

  }
  .productDescriptionDesc{
    height: 620px;
  }

  .right{
    flex: 2;
    padding-left: 1vw;
    margin-top: -30px;
  }
  .productImageWrapper {
    display: flex;
    justify-content: left;
    align-items: center;
    overflow:visible; /*TOTO JSEM PŘEDĚLAT Z NONE NA VISIBLE*/
  }
  
  .productImage {
    max-width: 60%;  
    max-height: 43vh; 
    height: auto; 
    width: auto; 
    border-radius: 10%;
    object-fit: contain;
  }

  .productPrice{
    font-family: 'Arial', sans-serif;  /* Profesionální písmo */
    font-size: 1.2rem;  /* Mírně větší velikost písma pro lepší čitelnost */
    font-weight: bold;  /* Zvýrazněná cena */
    color: #333;  /* Tmavě šedá barva pro text */
    margin: 0;  /* Bez okrajů */
    padding: 0;  /* Bez vnitřních okrajů */
    display: inline-block;  /* Zajistí, že text bude na jedné řadě */
    text-align: left;  /* Zarovnání textu doleva */
  }

 .productTitle h2{
    display: block;
    font-size: 1.35rem;
    font-weight: normal;
    font-family: Arial, Helvetica, sans-serif;
 }
 .productDescription{
    display: flex;
    flex-direction: column;
    
 }

 .add{
    width: 250px;
    padding: 10px;
    background-color: #2879fe;
    color: white;
    border: 1px solid #2879fe;
    cursor: pointer;                    
    font-weight: 500;
    font-size: 16px;
    transition: all 0.3s ease-in;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
 }

 .productDescription h4 {
    margin-top: -15px;
    font-weight: 500;
    font-size: 15px;
    margin-left: 2.5px;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #2879fe;
 }

 .productDescription h5{
    margin-top: -2.5px;
    margin-left: 2.5px;
 }

 .productDescription .quantity{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: -15px;
    position: relative;
    margin-bottom: 20px;
 }

 .productDescription .quantity input{
    width: 52px;
    padding: 2px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 18px;
    transition: border-color 0.3s ease;
 }
 .productDescription .quantity input:focus{
    border-color: #007bff;
    outline: none;
 }

 .productDescription .quantity input::placeholder{
    color: #999;
 }

 .productDescription .quantity button{
    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    border: none;    
                    background-color: #3f89ff;
                    color: white;
 }






  /* Specific styling for the right column to have a scrollable grid */
  .right{
     /* Full height to enable scrolling within the .right */


  }
  .similiarProducts{
    overflow-y: auto;
    overflow-x:hidden;
    height: 56vh;
    padding: 2%;
    border-radius: 10px;
  }
  .right .similiarProducts-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* Creates 6 columns */
    grid-auto-rows: minmax(115px, auto); /* Adjust row height as needed, starting at 100px */
    gap: 10px; /* Spacing between grid items */
    overflow-y: auto; 
    overflow-x:hidden;

  }
  
  /* Ensure images and other content scale properly within their flex containers */
  .left img, .middle .productDetail, .right .similiarProducts {
    width: 100%; /* Scale width to fit parent */
    /* Add additional styling as necessary */
  }
  

  
  @media screen and (orientation: portrait) and (min-width: 300px) {

    .productItemDetailsWrapper{
        flex-direction: column;
        margin-top: 10px;
        width: 98%;

    }
    .left{
        width: 100%;
        justify-content: center;
        text-align:center;
    }
    .productImage{
        border: 1px solid transparent;
        border-radius: 25px;
        background-clip: padding-box, border-box;
        min-width: 300px;       
        
        height: 200px;
        object-fit: contain;
        justify-content: center;
        margin:  auto;

    }
    .productDescription .quantity{
        justify-content: center;
    }
    .productDescription .add{
        justify-content: center;
        margin:auto;
    }
    .right{
        width: 95%;
        padding-left: 0px;
        margin: auto;

       


    }
    .right h3{
        margin-top: 40px;
        font-size: 14px;
        font-weight: 500;
        text-align: center;
    }
    .similiarProducts{
        overflow: visible;
        height: fit-content;
        margin: auto;
        overflow-x: hidden;
        border: none;
        padding: 0px;
        box-shadow: none;

    }
    .productTitle h2{
        font-size: 1.25rem;
    }
  
    .right .similiarProducts-grid {
      border: none;
        padding-left: 0px;
        display: grid;
        width: 90vw;
        margin: auto;
        grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));        
        gap: 2px; /* Spacing between grid items */
        overflow-x: hidden;

      }

  }

  @media screen and (orientation: portrait) and (min-width: 768px) {
    .right .similiarProducts-grid {
        padding-left: 0px;
        display: grid;
        margin-left: 0px;
        margin: auto;
        grid-template-columns: repeat(5, 1fr);         
        gap: 2px; /* Spacing between grid items */
        overflow-x: hidden;

      }
    
  }


  @media screen and (min-width: 1340px) and (max-width: 1559px) and (orientation: landscape) {
    .productItemDetailsWrapper{
      max-width: 1000px;
      height: 500px;
      min-height: fit-content;
      max-height: 500px;
      margin-bottom: 20px;
    }
    .productImageWrapper .productImage{
      max-width: 150px;
      max-height: 100px;
      width: 150px;
      height: 100px;
    }
  
    .right .similiarProducts-grid {
      grid-template-columns: repeat(4, 1fr);         

    }
  }